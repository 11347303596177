//https://github.com/cjb/serverless-webrtc/blob/35156b113c0c154ec80c14fcaf8e247d0e4b43bb/js/serverless-webrtc.js

export default class RtcService {
  private connection: RTCPeerConnection | null = null;
  private dataChannel: RTCDataChannel | null = null;
  public onOffer: (offer: string) => void = () => { };
  public onOpenDataChannel: () => void = () => { };
  public onCloseDataChannel: () => void = () => { };
  public onErrorDataChannel: (error: DOMException) => void = () => { };
  public onMessage: (message: string) => void = () => { };

  private start() {
    this.connection = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
      ],

    });
    this.connection.addEventListener("icecandidate", (e) => {
      if (e.candidate == null && this.connection) {
        this.onOffer(JSON.stringify(this.connection.localDescription));
      }
    });
  }

  public async startServer() {
    this.start();
    if (!this.connection) {
      return;
    }
    this.dataChannel = this.connection.createDataChannel("test", {});
    this.setupDataChannel();
    const offerDesc = await this.connection.createOffer();
    await this.connection.setLocalDescription(offerDesc);
  }

  public addClientToServer(answer: string) {
    if (!this.connection) {
      return;
    }
    const offerDesc = new RTCSessionDescription(JSON.parse(answer));
    this.connection.setRemoteDescription(offerDesc);
  }

  public async startClient(offer: string) {
    this.start();
    if (!this.connection) {
      return;
    }
    this.connection.addEventListener("datachannel", (e) => {
      this.dataChannel = e.channel;
      this.setupDataChannel();
    });
    const offerDesc = new RTCSessionDescription(JSON.parse(offer));
    this.connection.setRemoteDescription(offerDesc);
    const answer = await this.connection.createAnswer();
    this.connection.setLocalDescription(answer);
  }

  private setupDataChannel() {
    if (!this.dataChannel) {
      return;
    }
    this.dataChannel.addEventListener("open", this.onOpenDataChannel);
    this.dataChannel.addEventListener("message", (e) => this.onMessage(e.data));
    this.dataChannel.addEventListener("close", this.onCloseDataChannel);
    this.dataChannel.addEventListener("error", (e) =>
      this.onErrorDataChannel(e.error)
    );
  }

  public sendMessage(message: string) {
    if (!this.dataChannel) {
      return;
    }
    this.dataChannel.send(message);
  }
}
