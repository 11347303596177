































import { Component, Vue } from "vue-property-decorator";
import RctService from "../services/RtcService";

@Component({})
export default class Home extends Vue {
  private service: RctService = new RctService();
  private state = "";
  private messages: { id: number; source: string; message: string }[] = [];
  private currentId = 0;
  private showServerDetails = true;
  private clientCode = "";
  private showClientFields = false;
  private inviteCode = "";
  private online = false;
  private message = "";

  private async startServer() {
    this.service.onOffer = this.showOffer;
    this.service.onOpenDataChannel = () => {
      this.state = "Open";
    };
    this.service.onCloseDataChannel = () => {
      this.state = "Closed";
    };
    this.service.onErrorDataChannel = (error: DOMException) => {
      this.state = "error: " + error.message;
    };
    this.service.onMessage = (message: string) => {
      this.currentId++;
      this.messages.push({
        id: this.currentId,
        source: "them",
        message: message,
      });
    };
    await this.service.startServer();
    this.state = "Ready for Client";
  }

  private addClient(){
    this.service.addClientToServer(this.clientCode);
    if(!this.online){
      this.online = true;
    }
  }

  private sendMessage() {
    if (this.state == "") {
      return;
    } else {
      this.currentId++;
      this.messages.push({
        id: this.currentId,
        source: "them",
        message: this.message,
      });
      this.service.sendMessage(this.message);
      this.message = "";
    }
  }

  private showOffer(offer: string) {
    this.showClientFields = true;
    this.inviteCode = offer;
  }
}
